import { useTranslation } from "react-i18next";
import Carousel from "react-bootstrap/Carousel";
import "./RoomsSection.scss";
import Container from "react-bootstrap/Container";

function RoomsSection() {
  const { t } = useTranslation();

  return (
    <section id="roomsSection" className="pt-4">
      <h2 className="ms-4">{t("Rooms")}</h2>
      <Container className="carousel-container">
        <Carousel id="rooms-carousel">
          <Carousel.Item>
            <img className="d-block w-100" src="images/Corallo_c.JPG" alt="" />
            <Carousel.Caption>
              <h3>Corallo</h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="images/zaffiroRoom.jpg"
              alt=""
            />

            <Carousel.Caption>
              <h3>Zaffiro</h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="images/turchese_v1.JPG"
              alt=""
            />

            <Carousel.Caption>
              <h3>Turchese</h3>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </Container>
    </section>
  );
}

export default RoomsSection;
