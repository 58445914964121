import { useTranslation } from "react-i18next";
import "./CitySection.scss";

function CitySection() {
  const { t } = useTranslation();
  return (
    <section id="citySection" className="pt-4">
      <h2 className="ms-4">{t("City")}</h2>
      <p className="mx-4">{t("City Description")}</p>
    </section>
  );
}

export default CitySection;
