import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faGlobeAmericas,
  faHome,
  faCalendarAlt,
  faWalking,
  faBed,
  faPlaceOfWorship,
  faMapMarkerAlt,
  faMapMarkedAlt,
  faPhone,
  faEnvelope,
  faBus,
  faCheckCircle,
  faCookie,
  faGlassWhiskey,
  faWifi,
  faCoffee,
} from "@fortawesome/free-solid-svg-icons";

function initializeIconsLibrary() {
  library.add(
    faCoffee,
    faCalendarAlt,
    faGlobeAmericas,
    faHome,
    faWalking,
    faBed,
    faPlaceOfWorship,
    faMapMarkedAlt,
    faMapMarkerAlt,
    faPhone,
    faEnvelope,
    faBus,
    faCheckCircle,
    faCookie,
    faGlassWhiskey,
    faWifi
  );
}

export default initializeIconsLibrary;
