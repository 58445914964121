import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import { en, it } from "./languages/all";

function initializeTranslationModule() {
  i18n
    .use(detector)
    .use(initReactI18next)
    .init({
      // you can also manage translations via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
      resources: {
        en: {
          translation: en,
        },
        it: {
          translation: it,
        },
      },
      fallbackLng: "en",

      interpolation: {
        escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      },
    });
}

export default initializeTranslationModule;
