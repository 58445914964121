import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import "./DiscoverSection.scss";

function DiscoverSection() {
  const { t } = useTranslation();
  return (
    <section id="discoverSection" className="pt-4">
      <h2 className="ms-4">{t("Discover")}</h2>
      {/* cards */}
      <Container>
        <Row>
          <Col md={4} className="py-2">
            <Card className="h-100 nature-card">
              <Card.Img
                variant="top"
                className="img-fluid"
                src="images/nature.jpg"
                alt="Nature image"
              />
              <Card.Body>
                <Card.Title>{t("Nature")}</Card.Title>
                <Card.Text>{t("Nature Description")}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="py-2">
            <Card className="h-100 culture-card">
              <Card.Img
                variant="top"
                className="img-fluid"
                src="images/cattedrale.jpg"
                alt="Culture image"
              />
              <Card.Body>
                <Card.Title>{t("Culture")}</Card.Title>
                <Card.Text>{t("Culture Description")}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="py-2">
            <Card className="h-100 food-card">
              <Card.Img
                variant="top"
                className="img-fluid"
                src="images/Cannoli.jpg"
                alt="Food image"
              />
              <Card.Body>
                <Card.Title>{t("Food")}</Card.Title>
                <Card.Text>{t("Food Description")}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default DiscoverSection;
