import { useTranslation } from "react-i18next";
import "./LocationSection.scss";

function LocationSection() {
  const { t } = useTranslation();
  return (
    <section id="locationSection" className="pt-4">
      <h2 className="ms-4">{t("Location")}</h2>
      <div className="mx-4">
        <p>{t("Location Description")}</p>
        <iframe
          title="location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3169.9767486447317!2d14.369631715149337!3d37.39038214195462!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13111ed646d87005%3A0x939c94c6f0ed3a14!2sLuna%20Chiara!5e0!3m2!1sen!2sit!4v1640544877900!5m2!1sen!2sit"
          height="450"
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
        ></iframe>
      </div>
    </section>
  );
}

export default LocationSection;
