import "./BookSection.scss";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function BookSection() {
  const { t } = useTranslation();
  return (
    <section id="bookSection">
      <div className="title-and-button-container">
        <p className="title"> {t("Reserve Headline")}</p>
        <button
          className="btn btn-lg btn-success book-button text-uppercase"
          onClick={() =>
            window
              .open(
                "https://www.booking.com/hotel/it/bed-and-breakfast-luna-chiara.en-gb.html",
                "_blank"
              )
              ?.focus()
          }
        >
          <FontAwesomeIcon
            icon={["fas", "calendar-alt"]}
            fontSize="18px"
            className="me-2"
          />
          {t("Book")}
        </button>
      </div>
    </section>
  );
}

export default BookSection;
