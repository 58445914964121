import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Header.scss";
import { useTranslation } from "react-i18next";

function Header() {
  const { t, i18n } = useTranslation();
  return (
    <Navbar expand="lg" id="top-navbar">
      <Container fluid>
        <Navbar.Brand href="/">Luna Chiara B&B</Navbar.Brand>
        <NavDropdown
          className="order-lg-3"
          title={
            <>
              <FontAwesomeIcon
                icon={["fas", "globe-americas"]}
                fontSize="20px"
                className="me-2"
              />
              <span className="d-none d-sm-inline text-uppercase">
                {" "}
                {t("Language")}
              </span>
            </>
          }
          id="basic-nav-dropdown"
        >
          <NavDropdown.Item
            className={
              i18n.language.trim() === "it" ? "dropdown-item-selected" : ""
            }
            onClick={() => i18n.changeLanguage("it")}
          >
            {t("Italian")}
          </NavDropdown.Item>
          <NavDropdown.Item
            href=""
            className={
              i18n.language.trim() === "en" ? "dropdown-item-selected" : ""
            }
            selected={i18n.language.includes("en")}
            onClick={() => i18n.changeLanguage("en")}
          >
            {t("English")}
          </NavDropdown.Item>
        </NavDropdown>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
            <Nav.Link href="#home" className="text-uppercase">
              <FontAwesomeIcon
                icon={["fas", "home"]}
                fontSize="18px"
                className="me-2"
              />
              {t("Home")}
            </Nav.Link>
            <Nav.Link href="#discoverSection" className="text-uppercase">
              <FontAwesomeIcon
                icon={["fas", "walking"]}
                fontSize="20px"
                className="me-2"
              />
              {t("Discover")}
            </Nav.Link>
            <Nav.Link href="#roomsSection" className="text-uppercase">
              <FontAwesomeIcon
                icon={["fas", "bed"]}
                fontSize="18px"
                className="me-2"
              />
              {t("Rooms")}
            </Nav.Link>
            <Nav.Link href="#citySection" className="text-uppercase">
              <FontAwesomeIcon
                icon={["fas", "place-of-worship"]}
                fontSize="19px"
                className="me-2"
              />
              {t("City")}
            </Nav.Link>
            <Nav.Link href="#locationSection" className="text-uppercase">
              <FontAwesomeIcon
                icon={["fas", "map-marked-alt"]}
                fontSize="18px"
                className="me-2"
              />
              {t("Location")}
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
