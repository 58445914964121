import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./ServicesSection.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ServicesSection() {
  const { t } = useTranslation();
  return (
    <section id="servicesSection" className="pt-4">
      <h2 className="ms-4">{t("Services")}</h2>
      <Container>
        <Row>
          <Col className="py-2 text-center">
            <FontAwesomeIcon icon={["fas", "wifi"]} fontSize="40px" />
            <p>{t("Free WiFi")}</p>
          </Col>
          <Col className="py-2 text-center">
            <FontAwesomeIcon icon={["fas", "coffee"]} fontSize="40px" />
            <p> {t("Free Breakfast")}</p>
          </Col>
          <Col className="py-2 text-center">
            <FontAwesomeIcon icon={["fas", "glass-whiskey"]} fontSize="40px" />
            <p> {t("Free Drinks")}</p>
          </Col>
          <Col className="py-2 text-center">
            <FontAwesomeIcon icon={["fas", "cookie"]} fontSize="40px" />
            <p> {t("Free Snacks")}</p>
          </Col>
          <Col className="py-2 text-center">
            <FontAwesomeIcon icon={["fas", "check-circle"]} fontSize="40px" />
            <p> {t("Flexible CheckIn")}</p>
          </Col>
          <Col className="py-2 text-center">
            <FontAwesomeIcon icon={["fas", "check-circle"]} fontSize="40px" />
            <p> {t("Flexible CheckOut")}</p>
          </Col>
          <Col className="py-2 text-center">
            <FontAwesomeIcon icon={["fas", "bus"]} fontSize="40px" />
            <p> {t("Close to the bus stop")}</p>
          </Col>
          <Col className="py-2 text-center">
            <FontAwesomeIcon icon={["fas", "map-marker-alt"]} fontSize="40px" />
            <p> {t("In the city center")}</p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default ServicesSection;
