import "./App.scss";
import Header from "./components/Header";
import Footer from "./components/Footer";
import BookSection from "./components/BookSection";
import DiscoverSection from "./components/DiscoverSection";
import CitySection from "./components/CitySection";
import LocationSection from "./components/LocationSection";
import RoomsSection from "./components/RoomsSection";
import ServicesSection from "./components/ServicesSection";

function App() {
  return (
    <>
      <Header />
      <BookSection />
      <DiscoverSection />
      <RoomsSection />
      <ServicesSection />
      <CitySection />
      <LocationSection />
      <Footer />
    </>
  );
}

export default App;
