import { useTranslation } from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import "./Footer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Footer() {
  const { t } = useTranslation();
  return (
    <footer className="page-footer font-small blue pt-4">
      <Container fluid className="text-md-left">
        <Row>
          <Col md={6} className="mt-md-0 mt-3">
            <h5 className="text-uppercase fw-bolder">
              Bed and breakfast Luna Chiara
            </h5>
            <address>
              Piazza Senatore Marescalchi, 5 <br />
              Piazza Armerina (EN), 94015, Italy <br />
            </address>
          </Col>
          {/* <hr className="clearfix w-100 d-md-none pb-3" /> */}
          <Col md={3} className="mb-md-0 mb-3 features">
            <h5 className="text-uppercase ">{t("Features")}</h5>
            <ul className="list-unstyled info">
              <li>
                <a
                  href="https://www.booking.com/hotel/it/bed-and-breakfast-luna-chiara.en-gb.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("Book")}
                </a>
              </li>
              <li>
                <a href="#roomsSection">{t("Rooms")}</a>
              </li>
              <li>
                <a href="#servicesSection">{t("Services")}</a>
              </li>
              <li>
                <a href="#citySection">{t("City")}</a>
              </li>
              <li>
                <a href="#locationSection">{t("Location")}</a>
              </li>
            </ul>
          </Col>
          <Col md={3} className="mb-md-0 mb-3 contactus">
            <h5 className="text-uppercase ">{t("Contact Us")}</h5>
            <ul className="list-unstyled contact">
              <li>
                <FontAwesomeIcon icon={["fas", "phone"]} />
                <a href="tel:+393248998066">
                  {" "}
                  +39 3248998066 ({t("Footer-Phone-Number-Info-IT")})
                </a>
              </li>
              <li>
                <FontAwesomeIcon icon={["fas", "phone"]} />
                <a href="tel:+34685528502">
                  {" "}
                  +34 685528502 ({t("Footer-Phone-Number-Info-EN-ES")})
                </a>
              </li>
              <li>
                <FontAwesomeIcon icon={["fas", "envelope"]} />
                <a href="mailto:lunachiarabed@hotmail.it">
                  {" "}
                  lunachiarabed@hotmail.it
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
